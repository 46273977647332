import axios from 'axios';
import store from 'redux/store';
import Toast from 'Utils/Toast/Toast';
import { loginFail } from './authActions';

export const handleError = <E>(error: E | any): E => {
  if (axios.isCancel(error)) {
    // console.log('request cancelled', error);
    return error;
  }

  if (!error.response) {
    //NOTE:this will also happen if server request fails
    // error.toJSON()
    Toast({
      msg: 'Connection timeout',
      type: 'danger',
      // notify: true,
    });
    return error;
  }

  if (error.response.data instanceof Blob && error.response.data?.type === 'text/plain') {
    error.response.data.text().then((e: any) => {
      Toast({
        msg: e,
        type: 'danger-outline',
        notify: true,
      });
    });
    return error;
  }

  if (error.response.status === 403) {
    if (
      error.response.data instanceof Blob &&
      (error.response.data?.type === 'text/xml' || error.response.data?.type === 'text/plain')
    ) {
      error.response.data.text().then((e: any) => {
        Toast({
          msg: e,
          type: 'danger',
          notify: true,
        });
      });
      return error;
    }
    Toast({
      msg: error.response.data,
      type: 'danger',
      notify: true,
    });
    return error;
  }

  if (error.response.status === 401) {
    store.dispatch(loginFail());
    return error;
  }

  if (
    error.response.status >= 400 &&
    error.response.status <= 500 &&
    error.response.data.Message === undefined
  ) {
    //Check if it is the ModelError. If not =, return the usual error
    try {
      const obj = Object.keys(error.response.data.errors)[0];
      const err = error.response.data.errors[obj][0];

      if (err) {
        // Toast({ msg: err, type: 'danger-outline', notify: true });
        Toast({
          msg: (obj.split('.')[1] ?? obj) + '=>' + err.split('. Path')[0],
          type: 'danger-outline',
          notify: true,
        });
      }
      return error;
    } catch (errors) {
      Toast({
        msg: error.response.statusText,
        type: 'danger',
      });
      return error;
    }
  }

  if (error.response.status >= 400 && error.response.status <= 500) {
    Toast({
      msg: error.response.data.Message,
      type: 'danger',
    });
    return error;
  }

  if (typeof error.response.data === 'string') {
    Toast({
      msg: error.response.data,
      type: 'danger-outline',
      notify: true,
    });
    return error;
  }

  if (error.response.data.errors) {
    try {
      const obj = Object.keys(error.response.data.errors)[0];
      const err = error.response.data.errors[obj][0];
      // const err2 = error.response.data.title;
      if (err) {
        // Toast({ msg: err, type: 'danger-outline', notify: true });
        Toast({
          msg: (obj.split('.')[1] ?? obj) + '=>' + err.split('. Path')[0],
          type: 'danger-outline',
          notify: true,
        });
      }
      // if (err2) {
      //   Toast({ msg: err2, type: 'danger', notify: true });
      // }
      return error;
    } catch (error: any) {
      Toast({
        msg: 'Could not get error message',
        type: 'danger-outline',
        notify: true,
      });
      return error;
    }
  }
  return error;
};
