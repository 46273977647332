import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import Loader from './components/Loader/Loader';
import { QueryClientProvider } from 'react-query';
import { queryClient, setup } from './Utils/appUtil';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './pages/ErrorBoundary/ErrorBoundary';

const Main = lazy(() => import(/* webpackChunkName: "Main" */ './Main'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ './pages/Auth/Login'));
const Login2Fa = lazy(() => import(/* webpackChunkName: "LoginF2a" */ 'pages/Auth/Login2Fa'));
const Register = lazy(() => import(/* webpackChunkName: "Register" */ './pages/Auth/Register'));
const ProcessClientWithdrawal = lazy(
  () =>
    /*webpackChunkName:  Client Withdrawal STP Authorization */ import(
      'pages/ClientAdmin/ClientWithdrawal/ProcessClientWithdrawal'
    )
);
const PasswordReset = lazy(
  () => import(/* webpackChunkName: "PasswordReset" */ './pages/Auth/PasswordReset')
);
const ApproveRejectWorkflowProcess = lazy(
  () =>
    import(
      /* webpackChunkName: "ApproveRejectWorkflowProcess" */ './pages/Setups/WorkFlow/ApproveRejectWorkflowProcess/ApproveRejectWorkflowProcess'
    )
);
const ProtectedRoute = lazy(
  () => import(/* webpackChunkName: "ProtectedRoute" */ './pages/Auth/ProtectedRoute')
);

const App = () => {
  useEffect(() => {
    setup();
    // eslint-disable-next-line
  }, []);

  return (
    <ErrorBoundary>
      <ToastContainer className="custom-toast-container" progressClassName="Toastify__progress-bar" />
      <Suspense fallback={<Loader />}>
        <QueryClientProvider client={queryClient} contextSharing>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <Switch>
            <Route component={PasswordReset} path="/passwordreset/:token" />
            <Route component={ApproveRejectWorkflowProcess} path="/workflow/:token" />
            <Route component={Register} path="/register" />
            <Route component={Login} path="/login" />
            <Route component={Login2Fa} path="/login2fa/:token" />
            <Route component={ProcessClientWithdrawal} path="/clientwithdrawalstp/:token" />
            <ProtectedRoute component={Main} path="/" />
          </Switch>
        </QueryClientProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
